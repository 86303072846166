import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  http = inject(HttpClient);
  authService = inject(AuthService);

  changeUnitState = new BehaviorSubject(false);

  all(uid: number, s = '', status = -1) {
    let body: any = {
      uid,
    };

    if (s) {
      body.s = s;
    }

    if (status >= 0) {
      body.status = status;
    }

    return this.http.post<any>(`${env.urlBase}/userStatusCollection`, body);
  }

  unidades() {
    if (this.authService.isSuper()) {
      return this.http.get<any>(`${env.urlBase}/getUnidades/0`);
    }
    let uid = this.authService.unidadeSelecionada.id;
    if (!uid) uid = '0';
    return this.http.get<any>(`${env.urlBase}/getUnidades/${uid}`);
  }

  alterarUsuarios(objeto: any) {
    const cids: any[] = [];
    objeto.selecionados.map((usuario: any) => {
      cids.push({ cid: usuario.cid });
    });

    const body = {
      userStatusUpdate: cids,
      Active: objeto.ativo,
      Unidade_id: objeto.id_unidade,
      Unidade_nome: objeto.nome_unidade,
    };
    return this.http.post<any>(`${env.urlBase}/userStatusUpdate`, body);
  }

  deleteUsuario(usuarios: any[]) {
    const jsonObj = [];
    for (let usuario of usuarios) {
      jsonObj.push({ cid: usuario.cid });
    }

    const body = {
      usuarios: jsonObj,
    };

    return this.http.post<any>(`${env.urlBase}/deleteUsuario`, body);
  }

  getConfiguracaoUnidade(id: number): any {
    return this.http.get(`${env.urlBase}/getUnidade/${id}`);
  }
}
